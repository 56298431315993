import {
	ApolloClient,
	NormalizedCacheObject,
	ApolloProvider,
} from '@apollo/client';
import { useState, useEffect, ReactNode } from 'react';
import { getApolloClient } from '../client/apolloClient';
import { getLogger } from '../utils/logger';

export const ApolloClientProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: { children: ReactNode }) => {
	const [applloClient, setApolloClient] =
		useState<ApolloClient<NormalizedCacheObject> | null>(null);
	const logger = getLogger();

	useEffect(() => {
		getApolloClient()
			.then(setApolloClient)
			.catch((err): void => {
				logger.error('Failed to Get Apollo Client:', err);
			});
	}, [logger]); // for Linting Compliance

	if (!applloClient) {
		return <div data-testid="loading">Loading Apollo Client...</div>;
	} else {
		return (
			<ApolloProvider client={applloClient}>
				<div data-testid="apollo-provider">{children}</div>
			</ApolloProvider>
		);
	}
};
