import { useEffect, useState } from 'react';
import axios from 'axios';
import { getCurrentUser } from '../utils/auth';
import { querySettings } from '../utils/queryUtils';
import { getLogger } from '../utils/logger';

// Defining the expected structure of the API response
interface EvaluateFeatureResponse {
	value: {
		boolValue: boolean;
	};
}

export type EvaluateFeatureHook = (props: {
	fallback: boolean;
}) => [boolean, boolean];

let evidentlyUrl = '';

export const useEvaluateFeature: EvaluateFeatureHook = ({ fallback }) => {
	const [returnValue, setReturnValue] = useState<
		ReturnType<EvaluateFeatureHook>
	>([fallback, true]);
	const logger = getLogger();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const authUser = await getCurrentUser();
				const entityId = authUser.vibeId;
				const response = await evaluateFeature({ entityId });
				const val = handleResponse(response, fallback, logger);
				setReturnValue([val, false]);
			} catch (error) {
				logger.error(
					`Failed to evaluate feature: SuperGraph and project: SBAIRLaunches with error: ${error}`,
				);
				setReturnValue([fallback, false]);
			}
		};

		fetchData();
	}, [fallback, logger]);

	return returnValue;
};

const handleResponse = (
	response: { data: EvaluateFeatureResponse | null; loading: boolean },
	fallback: boolean,
	logger: ReturnType<typeof getLogger>,
) => {
	if (response?.data?.value?.boolValue === undefined) {
		logger.error(
			'Evaluated featured did not return the expected type',
			response,
		);
		return fallback;
	}
	return response?.data?.value?.boolValue;
};

export const evaluateFeature = async ({
	entityId,
}: {
	entityId: string;
}): Promise<{ data: EvaluateFeatureResponse | null; loading: boolean }> => {
	if (!evidentlyUrl) {
		const settings = await querySettings();
		evidentlyUrl = settings?.evidently?.url;
	}

	const response = await axios.post<EvaluateFeatureResponse>(evidentlyUrl, {
		entityId,
	});
	return { data: response?.data || null, loading: false };
};
