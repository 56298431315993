import { MemoryStorage } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { BifrostSettings } from '../interfaces/bifrostSettings';
import {
	GandalfUserSession,
	GandalfUser,
	CustomCognitoUser,
} from '../interfaces/gandalfUser';
import { getLogger } from '../utils/logger';
import { CognitoUser } from 'amazon-cognito-identity-js';

/**
 * Creates a `urlOpener` function to intercept sign in calls and exchange the url.
 * If no signInUIDomain has been defined the given Hosted UI url will be used.
 *
 *
 * @see {@link https://aws-amplify.github.io/amplify-js/api/interfaces/awscognitooauthopts.html}
 *
 * @param {string} domain Hosted UI domain
 * @param {string} signInUIDomain SignIn UI domain
 * @returns {Function(string): Promise} urlOpener function
 */

type UrlOpener = (url: string) => Promise<Window | null>;
const createUrlInterceptor =
	(domain: string, signInUIDomain: string): UrlOpener =>
	(url: string) => {
		const SELF = '_self';
		const loginRequestUrl = `https://${domain}/oauth2/authorize`;
		let windowProxy;

		if (signInUIDomain && url.startsWith(loginRequestUrl)) {
			const signInUrl = url.replace(
				loginRequestUrl,
				`https://${signInUIDomain}/login`,
			);
			windowProxy = window.open(signInUrl, SELF);
		} else {
			windowProxy = window.open(url, SELF);
		}
		return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
	};

/**
 * Create configuration based on settings.json
 */
export const createConfig = (settings: BifrostSettings) => {
	return {
		Auth: {
			storage: MemoryStorage,
			region: settings.auth.region,
			userPoolId: settings.auth.userPoolId,
			userPoolWebClientId: settings.auth.userPoolWebClientId,
			oauth: {
				scope: ['openid'],
				responseType: 'code',
				domain: settings.auth.oauthDomain,
				redirectSignIn: settings.auth.oauthRedirectSignIn,
				redirectSignOut: settings.auth.oauthRedirectSignOut,
				urlOpener: createUrlInterceptor(
					settings.auth.oauthDomain,
					'login.us-east-1.auth.skillbuilder.aws',
				),
			},
		},
	};
};

/**
 * Configure Amplify Auth with the configuration based on settings.json
 * @throws {Error} Rethrows the error from createConfig() and Auth.configure()
 */
export const configureAuth = (settings: BifrostSettings) => {
	const config = createConfig(settings);
	Auth.configure(config);
};

/*
 * Get the current logged-in CustomCognitoUser user and return the GandalfUser
 */
export const getCurrentUser = async (): Promise<GandalfUser> => {
	return Auth.currentAuthenticatedUser().then(
		(userData: CustomCognitoUser) => {
			return {
				vibeId: userData.signInUserSession.idToken.payload.vibe_user_id,
				hatId: userData.signInUserSession.idToken.payload.hat_id,
				userFullDisplayName: getUserFullName(
					userData.signInUserSession,
				),
			} as GandalfUser;
		},
	);
};

/**
 *
 * @returns
 */
export const getUserFullName = (userSession: GandalfUserSession): string => {
	return `${userSession.idToken.payload.given_name} ${userSession.idToken.payload.family_name}`;
};

/*
 * Sign in handler
 */
export const handleSignIn = () =>
	Auth.federatedSignIn().catch((error) => getLogger().error(error));

/*
 * Sign out handler
 */
export const handleSignOut = () =>
	Auth.signOut().catch((error) => getLogger().error(error));

/*
 * Get the current user's authorization ID token
 */
export const getAuthUserToken = async (): Promise<string | null> => {
	try {
		const user: CognitoUser = await Auth.currentAuthenticatedUser();
		return user.getSignInUserSession()?.getIdToken().getJwtToken() || null;
	} catch (error) {
		return null;
	}
};
