import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

export interface NotificationContextProps {
	visible: boolean;
	makeVisible: () => void;
	makeInvisible: () => void;
}

export const NotificationContext =
	createContext<NotificationContextProps | null>(null);

export const useNotificationContext = () => {
	const context = useContext(NotificationContext);
	if (context === null) {
		throw new Error(
			'useNotificationContext must be used within a NotificationContextProvider tag',
		);
	}

	return context;
};

interface NotificationContextProviderProps {
	children: ReactNode;
}

const NotificationContextProvider = ({
	children,
}: NotificationContextProviderProps) => {
	const [visible, setVisible] = useState(true);

	const makeVisible = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const makeInvisible = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	useEffect(() => {
		makeVisible();
	}, [makeVisible]);

	return (
		<NotificationContext.Provider
			value={{ visible, makeVisible, makeInvisible }}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationContextProvider;
