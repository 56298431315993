// Handles TypeScript linting when referencing AWSMA object
/* eslint-disable  @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		AWSMA: any;
	}
}

/**
 * @function analyticsClickEvent
 * @param suffixes - The strings to append to the adobe analytics event detail name, "admin|reports|click|{suffixes.join('|')}"
 */
export const analyticsClickEvent = (suffixes: string[]) => {
	return () => {
		window.AWSMA.ready(() => {
			document.dispatchEvent(
				new CustomEvent(window.AWSMA.TRIGGER_EVENT, {
					detail: {
						event: {
							type: 'click',
							name: `admin|reports|click|${suffixes.join('|')}`,
						},
						data: {},
					},
				}),
			);
		});
	};
};
