import { name as unauthorizedErrorName } from '../errors/UnauthorizedAdminAccessError';
import { BifrostSettings } from '../interfaces/bifrostSettings';

export const MAX_WIDTH = 1200;
export const ERROR_STRING = {
	NOT_FOUND: '404 Page Not Found',
	NOT_AUTHENTICATED: 'The user is not authenticated',
	GENERIC_HEADER: 'Something went wrong',
	GENERIC_MESSAGE: 'There was an error',
	RELOAD_MESSAGE: 'Please reload the page',
};

const ERRORS = new Map();
ERRORS.set(unauthorizedErrorName, {
	headerText: "You don't have access to reports",
	descriptionText:
		'Contact your administrator to learn more or change your permissions.',
	buttonText: 'Return to Skill Builder',
	buttonUrl: (settings: BifrostSettings | undefined) =>
		settings?.docebo?.homeUrl,
});

const UNSPECIFIED_ERROR = {
	headerText: ERROR_STRING.GENERIC_HEADER,
	descriptionText: ERROR_STRING.GENERIC_MESSAGE,
	buttonText: ERROR_STRING.RELOAD_MESSAGE,
	buttonUrl: (settings: BifrostSettings | undefined) =>
		settings?.skillbuilderReports?.administratorDasboard,
};

export const errorCopy = (errorName: string | undefined) => {
	if (ERRORS.has(errorName)) {
		return ERRORS.get(errorName);
	}
	return UNSPECIFIED_ERROR;
};
