export interface RetryOptions<T = unknown> {
	retries: number;
	interval: number;
	expBackOff: boolean;
	jitter: boolean;
	onError?: (error?: T) => void;
	onSuccess?: (response?: T) => void;
}

const defaultRetryOptions: RetryOptions = {
	retries: 1,
	interval: 1000,
	expBackOff: true,
	jitter: true,
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const calculateRetryDelay = (attempt: number, options: RetryOptions) => {
	const opts: RetryOptions = { ...defaultRetryOptions, ...options };
	const expBackOff = opts.expBackOff ? Math.pow(2, attempt) : 1;
	const jitter = opts.jitter ? Math.random() : 1;
	return Math.floor(opts.interval * jitter * expBackOff);
};

export async function retry<T = Response>(
	asyncFn: () => Promise<T>,
	options?: Partial<RetryOptions>,
): Promise<T> {
	const opts = { ...defaultRetryOptions, ...options };

	const retryHelper = (attempt = 1): Promise<T> =>
		asyncFn()
			.catch(async (error: unknown) => {
				if (opts.onError) {
					opts.onError(error);
				}
				if (attempt === opts.retries + 1) {
					return Promise.reject(error);
				}
				return delay(calculateRetryDelay(attempt, opts)).then(() =>
					retryHelper(attempt + 1),
				);
			})
			.then((value: T) => {
				if (opts.onSuccess) {
					opts.onSuccess(value);
				}
				return value;
			});

	return retryHelper();
}
