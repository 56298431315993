import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuthUserToken } from '../utils/auth';
import { querySettings } from '../utils/queryUtils';

export const getApolloClient = async (): Promise<
	ApolloClient<NormalizedCacheObject>
> => {
	const settings = await querySettings();
	const apolloUri = settings.apollo.url;

	const httpLink = createHttpLink({
		uri: apolloUri,
		credentials: 'include',
		preserveHeaderCase: true,
	});

	const authLink = setContext(async (_, { headers }) => {
		const jwt = await getAuthUserToken();
		return {
			headers: {
				...headers,
				Authorization: jwt ? `Bearer ${jwt}` : '',
			},
		};
	});

	return new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
	});
};
