import React from 'react';
import ReactDOM from 'react-dom';

import App from '../components/App';
import { ErrorPageWithFullLayout } from '../pages/ErrorPage';
import ErrorBoundary from '../components/ErrorBoundary';
import AppRootContextProviders from '../contexts/AppRootContextProviders';
import { getLogger } from './logger';
import { DimensionValue } from '../interfaces/logger';

export const wrap =
	<P extends object>(
		ChildComponent: (ChildComponentProps: P) => JSX.Element,
	) =>
	(ChildComponentProps: P) =>
		(
			<React.StrictMode>
				<AppRootContextProviders>
					<ErrorBoundary isOutsideRouter={true}>
						<ChildComponent {...ChildComponentProps} />
					</ErrorBoundary>
				</AppRootContextProviders>
			</React.StrictMode>
		);

export const logRootLevelError = (error: Error) => {
	const logger = getLogger();
	logger.error('Error captured at root level', error);
	logger.counterMetric({
		metricName: 'RootError',
		dimensionValue: DimensionValue.Error,
	});
};

export const renderApplication = (element: HTMLElement) => {
	const WrappedApp = wrap(App);
	ReactDOM.render(<WrappedApp />, element);
};

export const renderErrorPage = (element: HTMLElement) => {
	const WrappedErrorPageWithFullLayout = wrap(ErrorPageWithFullLayout);
	ReactDOM.render(<WrappedErrorPageWithFullLayout />, element);
};

export const renderLastResortErrorPage = (element: HTMLElement) => {
	element.innerHTML = `
      <div style="max-width: 80rem;margin: 2rem auto 0 auto; padding: 2rem;">
        <h1>Uh oh, something went wrong.</h1>
        <p>Please <a href="/">reload the page</a> and try again.</p>
        <a href="${'https://www.amazon.com/dogsofamazon'}" target="_blank" rel="noopener noreferrer">
          <img style="max-width: 100%" alt="Meet the dogs of Amazon." src="/waffles.jpg"/>
        </a>
      </div>`;
	document.body.appendChild(element);
};
