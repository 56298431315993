import { HandleRequestData } from '../interfaces/handleRequest';
import { retry, RetryOptions } from './retry';
import UnauthorizedAdminAccessError from '../errors/UnauthorizedAdminAccessError';
import { QuickSightUrl } from './handleQuickSightApiRequest';

const ERROR_MESSAGE_REGEX = /Failed to fetch with status \d+ and response .+/g;

export const encodeErrorMessage = (status: number, result: unknown) =>
	`Failed to fetch with status ${status} and response ${JSON.stringify(
		result,
	)}`;

export const decodeErrorMessage = (message: string) => {
	if (ERROR_MESSAGE_REGEX.test(message)) {
		const strings = message.split(' ');
		const status = parseInt(strings[5]);
		const result = JSON.parse(strings[8]);

		return { status, result };
	} else {
		throw Error(`Error message does not match the pattern ${message}`);
	}
};

const handleRequest = async <T = QuickSightUrl>(
	rawUrl: string,
	fetchOptions: RequestInit,
	retryOptions?: Partial<RetryOptions>,
): Promise<HandleRequestData<T>> => {
	let status = 0;
	let result = null;

	const url = new URL(rawUrl, window.location.origin);
	const currentSearchParams = new URLSearchParams(window.location.search);
	for (const [key, val] of currentSearchParams.entries()) {
		url.searchParams.append(key, val);
	}

	const response = await retry(() => fetch(url, fetchOptions), retryOptions);
	status = response.status;
	result = await response.json();
	// catch non-network errors like 403 custom errors from the APIs
	if (response.status === 403) {
		throw new UnauthorizedAdminAccessError(
			encodeErrorMessage(status, response),
		);
	}
	if (!response.ok) {
		throw new Error(encodeErrorMessage(status, result));
	}

	return {
		status,
		result,
	};
};
export default handleRequest;
