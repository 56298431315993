import React from 'react';
import './Header.scss';
import { HeaderLogoImg } from './Common';
import {
	Container,
	Header as PolarisHeader,
} from '@amzn/awsui-components-react/polaris';
import Link from '@amzn/awsui-components-react/polaris/link';
import useSettings from '../../hooks/useSettings';
import { analyticsClickEvent } from '../../analytics';

const Header = () => {
	const { settings } = useSettings();
	return (
		<Container className="header-wrapper">
			<PolarisHeader className="bifrost-header">
				<Link
					data-testid="HeaderLogoLinkDesktop"
					href={settings?.docebo?.homeUrl}
					children={<HeaderLogoImg isMobile={false} />}
					className="header__link_focus"
					ariaLabel="Return to administrator dashboard"
					onFollow={analyticsClickEvent(['nav', 'Logo'])}
				/>
			</PolarisHeader>
		</Container>
	);
};

export default Header;
